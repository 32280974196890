import { Button, FormHelperText, Stack } from "@mui/material";
import { useEffect, useState } from "react";

import { TextField } from "../../components";
import { useAuth } from "../../../services/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetAtom } from "jotai";
import { authTemp } from "../../../services/store";

const defaultValues = {
  email: "",
};

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const RequestResetForm = () => {
  const [genericError, setGenericError] = useState("");
  const navigate = useNavigate();
  const { handleForgotPassword } = useAuth();
  const setAuthTemp = useSetAtom(authTemp);

  const {
    control,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting, touchedFields },
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    setGenericError("");
    try {
      await handleForgotPassword(data.email);
      setAuthTemp({ email: data.email });
      navigate("/reset/password");
    } catch (error) {
      setGenericError(error.message);
    }
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <TextField
          control={control}
          rules={{
            required: "Email is required",
            pattern: {
              value: EMAIL_REGEX,
              message: "Please enter a valid email address",
            },
          }}
          name="email"
          error={touchedFields.email && !!errors.email}
          helperText={errors?.email?.message}
          label="Email"
          sx={{
            "& .MuiFormHelperText-root": {
              color: errors?.email ? "error.main" : "text.secondary",
              marginLeft: 0,
              marginTop: 1,
            },
          }}
        />
        <Button
          variant="contained"
          disabled={isSubmitting}
          type="submit"
          size="large"
        >
          Initiate Reset Request
        </Button>
        {genericError && (
          <FormHelperText
            error
            sx={{
              marginTop: 1,
              marginLeft: 0,
              fontSize: "0.875rem",
            }}
          >
            {genericError}
          </FormHelperText>
        )}
      </Stack>
    </form>
  );
};
