import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useAtom, useAtomValue } from "jotai";
import {
  canCompareDoctrinaState,
  canCompareJudgesState,
  compareDoctrinaState,
  compareJudgesState,
  doctrinaTabState,
  filterDrawerOpenState,
} from "../../../services/store";
import { Default } from "../../layouts";
import { Error } from "../Error";
import { useDoctrinaInfiniteData } from "../../../services/hooks/useDoctrinaInfiniteData";
import { useDoctrinaTabAutoSwitch } from "../../../services/hooks/useDoctrinaTabAutoSwitch";
import { DoctrinaContent } from "./DoctrinaContent";
import { DoctrinaHeader } from "./DoctrinaHeader";
import { useMemo, useCallback } from "react";

const Fetch = ({ onTabChange }) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [search] = useSearchParams();
  const searchParamsString = new URLSearchParams(search).toString();

  const isComparingDoctrina = useAtomValue(canCompareDoctrinaState);
  const isComparingJudges = useAtomValue(canCompareJudgesState);
  const [comparingDoctrina, setComparingDoctrina] =
    useAtom(compareDoctrinaState);
  const [comparingJudges, setComparingJudges] = useAtom(compareJudgesState);
  const [filterDrawerOpen, setFilterDrawerOpen] = useAtom(
    filterDrawerOpenState
  );

  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const firstCardRef = useRef(null);
  const mainContentWrapperRef = useRef(null);
  const buttonsRef = useRef(null);
  const searchAreaRef = useRef(null);
  const tabsRef = useRef(null);
  const filterRef = useRef(null);

  const {
    data: courtsQueryData,
    isLoading: isLoadingCourts,
    isFetchingNextPage: isFetchingNextPageCourts,
    fetchNextPage: fetchNextPageCourts,
    hasNextPage: hasNextPageCourts,
    isFetching: isFetchingCourts,
  } = useDoctrinaInfiniteData("courts", searchParamsString);

  const {
    data: judgesQueryData,
    isLoading: isLoadingJudges,
    isFetchingNextPage: isFetchingNextPageJudges,
    fetchNextPage: fetchNextPageJudges,
    hasNextPage: hasNextPageJudges,
    isFetching: isFetchingJudges,
  } = useDoctrinaInfiniteData("judges", searchParamsString);

  const courtsItems = useMemo(
    () => courtsQueryData?.pages.flatMap((page) => page.courts) ?? [],
    [courtsQueryData]
  );

  const judgesItems = useMemo(
    () => judgesQueryData?.pages.flatMap((page) => page.judges) ?? [],
    [judgesQueryData]
  );

  const tabValue = useDoctrinaTabAutoSwitch(
    searchParamsString,
    courtsItems.length,
    judgesItems.length
  );

  const isLoading = tabValue === 0 ? isLoadingCourts : isLoadingJudges;
  const isFetching = tabValue === 0 ? isFetchingCourts : isFetchingJudges;

  const handleFetchMore = useCallback(() => {
    if (tabValue === 0) {
      fetchNextPageCourts();
    } else {
      fetchNextPageJudges();
    }
  }, [tabValue, fetchNextPageCourts, fetchNextPageJudges]);

  return (
    <Stack
      sx={{
        height: "100vh",
        overflowY: "scroll",
        scrollbarGutter: "stable",
        "&::-webkit-scrollbar": {
          width: "16px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          border: "4px solid transparent",
          backgroundClip: "content-box",
        },
        pt: !md ? 10 : 0,
      }}
    >
      <Box ref={mainContentWrapperRef}>
        <DoctrinaHeader
          searchAreaRef={searchAreaRef}
          tabValue={tabValue}
          onTabChange={onTabChange}
          filterDrawerOpen={filterDrawerOpen}
          setFilterDrawerOpen={setFilterDrawerOpen}
          searchParamsString={searchParamsString}
          courtsItems={courtsItems}
          judgesItems={judgesItems}
          isComparingDoctrina={isComparingDoctrina}
          isComparingJudges={isComparingJudges}
          comparingDoctrina={comparingDoctrina}
          comparingJudges={comparingJudges}
          setComparingDoctrina={setComparingDoctrina}
          setComparingJudges={setComparingJudges}
          smUp={smUp}
          tabsRef={tabsRef}
          filterRef={filterRef}
        />

        <DoctrinaContent
          tabValue={tabValue}
          courtsItems={courtsItems}
          judgesItems={judgesItems}
          isLoadingCourts={isLoadingCourts}
          isLoadingJudges={isLoadingJudges}
          isFetching={isFetching}
          isLoading={isLoading}
          isFetchingNextPageCourts={isFetchingNextPageCourts}
          isFetchingNextPageJudges={isFetchingNextPageJudges}
          hasNextPageCourts={hasNextPageCourts}
          hasNextPageJudges={hasNextPageJudges}
          handleFetchMore={handleFetchMore}
          firstCardRef={firstCardRef}
          buttonsRef={buttonsRef}
        />
      </Box>
    </Stack>
  );
};

export const Doctrinas = () => {
  const [tabValue, setTabValue] = useAtom(doctrinaTabState);
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Default>
      <Box sx={{ position: "relative", minHeight: "100vh" }}>
        {!md && (
          <Box
            sx={{
              height: 80,
              borderBottom: 1,
              borderColor: "grey.100",
              bgcolor: "white",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1,
            }}
          />
        )}
        <Error>
          <Fetch onTabChange={handleTabChange} initialTabValue={tabValue} />
        </Error>
      </Box>
    </Default>
  );
};

export { Doctrinas as DoctrinasView };
