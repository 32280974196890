import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { doctrinaTabState } from "../store";

export const useDoctrinaTabAutoSwitch = (
  searchParamsString,
  courtsCount,
  judgesCount
) => {
  const [hasAutoSwitched, setHasAutoSwitched] = useState(false);
  const [tabValue, setTabValue] = useAtom(doctrinaTabState);

  useEffect(() => {
    const searchParams = new URLSearchParams(searchParamsString);
    const isTextSearch = searchParams.has("query");
    let timeoutId;

    if (!searchParamsString || hasAutoSwitched || !isTextSearch) return;

    if (tabValue === 0 && courtsCount === 0 && judgesCount > 0) {
      timeoutId = setTimeout(() => {
        setTabValue(1);
        setHasAutoSwitched(true);
      }, 500);
    } else if (tabValue === 1 && judgesCount === 0 && courtsCount > 0) {
      timeoutId = setTimeout(() => {
        setTabValue(0);
        setHasAutoSwitched(true);
      }, 500);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [
    searchParamsString,
    courtsCount,
    judgesCount,
    tabValue,
    setTabValue,
    hasAutoSwitched,
  ]);

  useEffect(() => {
    if (!searchParamsString) {
      setHasAutoSwitched(false);
    }
  }, [searchParamsString]);

  return tabValue;
};
