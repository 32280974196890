import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { jurisdictions, states } from "../../../services/store/data";
import { startTransition, useMemo } from "react";
import { hotjar } from "react-hotjar";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSuspenseQuery } from "@tanstack/react-query";
import { doctrinaNamesOptions } from "../../../services/queries";
import { useAtom } from "jotai";
import { doctrinaFilterState } from "../../../services/store";

export const defaultValues = {
  jurisdictions: [],
  state: [],
  court: null,
};

export const SearchFilters = ({ isJudgesTab = false, onClose }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterState, setFilterState] = useAtom(doctrinaFilterState);

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: filterState,
  });

  const { data: courts } = useSuspenseQuery(doctrinaNamesOptions());

  const courtOptions = useMemo(
    () =>
      courts.length &&
      courts?.map((court) => ({ label: court.name, id: court.id })),
    [courts]
  );

  useEffect(() => {
    const params = {};
    searchParams.forEach((value, key) => {
      if (key === "court_id") {
        const selectedCourt = courtOptions.find(
          (court) => court.id === parseInt(value)
        );
        params.court = selectedCourt || null;
      } else if (key === "query") {
        params.query = value;
      } else {
        params[key] = value.split(",").filter((v) => v);
      }
    });

    if (
      Object.keys(params).length === 0 &&
      filterState.jurisdictions.length > 0
    ) {
      setValue("jurisdictions", filterState.jurisdictions);
      setValue("state", filterState.state);
      setValue("court", filterState.court);
    } else {
      Object.entries(params).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [searchParams, setValue, courtOptions, filterState]);

  useEffect(() => {
    if (!searchParams.toString()) {
      reset(defaultValues);
      setFilterState(defaultValues);
    }
  }, [searchParams, reset, setFilterState]);

  const onSubmit = async (data) => {
    const queryParams = {};

    const existingQuery = searchParams.get("query");
    if (existingQuery) {
      queryParams.query = existingQuery;
    }

    if (data.state && data.state.length > 0) {
      queryParams.state = data.state.join(",");
    }

    if (data.jurisdictions && data.jurisdictions.length > 0) {
      queryParams.jurisdictions = data.jurisdictions.join(",");
    }

    if (data.court) {
      queryParams.court_id = data.court.id;
    }

    setFilterState(data);

    startTransition(() => {
      setSearchParams(queryParams);
      hotjar.event(
        isJudgesTab ? "search-judges-submit" : "search-courts-submit"
      );
    });
  };

  const handleResetAll = () => {
    reset(defaultValues);
    setFilterState(defaultValues);
    const existingQuery = searchParams.get("query");
    if (existingQuery) {
      setSearchParams({ query: existingQuery });
    } else {
      setSearchParams({});
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Stack
            direction="row"
            alignItems="flex-start"
            width="100%"
            sx={{
              "& .MuiAutocomplete-root": {
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "white",
                },
              },
              "& > div": {
                flex: 1,
                width: "auto",
                marginRight: "24px !important",
                "&:last-child": {
                  marginRight: "0 !important",
                },
              },
              [`@media (max-width: 1135px)`]: {
                flexDirection: "column",
                "& > div": {
                  width: "100%",
                  marginRight: "0 !important",
                  marginBottom: "24px !important",
                  "&:last-child": {
                    marginBottom: "0 !important",
                  },
                },
              },
            }}
          >
            <Stack spacing={1.5}>
              <Typography variant="subtitle2" color="text.secondary">
                Court
              </Typography>
              <Controller
                name="court"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={courtOptions}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    value={field.value || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search Courts"
                        variant="outlined"
                        size="small"
                        sx={{
                          minWidth: 250,
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "grey.300",
                            },
                            "&:hover fieldset": {
                              borderColor: "grey.400",
                            },
                          },
                        }}
                      />
                    )}
                  />
                )}
              />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2" color="text.secondary">
                Jurisdictions
              </Typography>
              <Controller
                name="jurisdictions"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    size="small"
                    options={jurisdictions}
                    getOptionLabel={(option) => option.label}
                    value={
                      field.value
                        ? jurisdictions.filter((jurisdiction) =>
                            field.value.includes(jurisdiction.id)
                          )
                        : []
                    }
                    onChange={(event, newValue) => {
                      field.onChange(newValue.map((item) => item.id));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Add jurisdictions"
                        variant="outlined"
                        sx={{
                          minWidth: 250,
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "grey.300",
                            },
                            "&:hover fieldset": {
                              borderColor: "grey.400",
                            },
                          },
                        }}
                      />
                    )}
                  />
                )}
              />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2" color="text.secondary">
                States
              </Typography>
              <FormControl>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      size="small"
                      options={states}
                      getOptionLabel={(option) => option.name}
                      value={
                        field.value
                          ? states.filter((state) =>
                              field.value.includes(state.abbreviation)
                            )
                          : []
                      }
                      onChange={(event, newValue) => {
                        field.onChange(
                          newValue.map((item) => item.abbreviation)
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Add states"
                          variant="outlined"
                          sx={{
                            minWidth: 250,
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "grey.300",
                              },
                              "&:hover fieldset": {
                                borderColor: "grey.400",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Stack>
          </Stack>

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Stack
              direction="row"
              spacing={2}
              width="100%"
              justifyContent="space-between"
            >
              <Button
                variant="outlined"
                onClick={onClose}
                sx={{
                  "&:hover": {
                    backgroundColor: "grey.100",
                  },
                }}
              >
                Close
              </Button>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  onClick={handleResetAll}
                  disabled={isSubmitting}
                >
                  Reset All Filters
                </Button>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  type="submit"
                >
                  Apply Filters
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
};
