import { Box, Paper, Stack, Typography } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import { RequestResetForm } from "./RequestResetForm";
import { Simple } from "../../layouts";

export const RequestReset = () => (
  <Simple>
    <Grid width="100%" height="100%" container>
      <Grid backgroundColor="white" xs={12} md={6}>
        <Stack height="100%" justifyContent="center" alignItems="center">
          <Box
            as="img"
            src={process.env.PUBLIC_URL + "/logo-full.png"}
            alt="Rhetoric"
            display="block"
            height="auto"
            width={[175, 375]}
            paddingY={[3, 0]}
            maxWidth="100%"
          />
        </Stack>
      </Grid>
      <Grid xs={12} md={6}>
        <Stack
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="100%" padding={[4, 6]}>
            <Paper>
              <Stack padding={6} spacing={4}>
                <Stack spacing={1}>
                  <Typography variant="h1">Forgot your password?</Typography>
                  <Typography variant="body1" color="text.light">
                    Input your email to initiate a reset.
                  </Typography>
                </Stack>
                <RequestResetForm />
                <Typography variant="body1" color="text.light">
                  <Link to="/login">Login</Link>
                </Typography>
              </Stack>
            </Paper>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  </Simple>
);
