import {
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { StatRadarGraph, normalizeData } from "../StatRadarGraph";
import { Suspense, useMemo, useState, forwardRef, useCallback } from "react";
import { compareJudgesState, removeItemAtIndex } from "../../../services/store";

import { AggregateTag } from "../AggregateTag";
import { CompareArrows as CompareArrowsIcon } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2";
import InfoIcon from "@mui/icons-material/Info";
import { OPINION_COUNT_LIMIT } from "../../../services/models";
import PreviewIcon from "@mui/icons-material/Preview";
import { StatPieGraphText } from "../StatPieGraphText";
import { hotjar } from "react-hotjar";
import { judgeOptions } from "../../../services/queries";
import { judgeSubjectsAtom } from "../../../services/store";
import { useAtom } from "jotai";
import { useAtomValue } from "jotai";
import { useContainerQuery } from "react-container-query";
import { useNavigate } from "react-router-dom";
import { useSuspenseQuery } from "@tanstack/react-query";

const shouldDisplayValue = (value) => {
  const unwantedValues = [
    "unknown",
    "unknown ()",
    "N/A",
    "n/a",
    "N/A ()",
    "unknown (9999)",
    "N/A (9999)",
  ];
  const stringValue = value == null ? "" : String(value).trim();
  return stringValue !== "" && !unwantedValues.includes(stringValue);
};

const tabs = ["jurisprudence", "sentiment", "tone"];

const query = {
  "width-between-500-and-820": {
    minWidth: 500,
    maxWidth: 820,
  },
};

const StatCardOne = ({ label, value }) => (
  <Stack direction="row" justifyContent="space-between" gap={1}>
    <Typography variant="body2">{label}:</Typography>
    <Typography variant="body2" textTransform="capitalize" align="right">
      <strong>{value}</strong>
    </Typography>
  </Stack>
);

const Fetch = forwardRef(({ id, isDetailPage = false, buttonsRef }, ref) => {
  const theme = useTheme();
  const [params] = useContainerQuery(query);
  const [comparing, setComparing] = useAtom(compareJudgesState);
  const isChecked = comparing.findIndex((compare) => compare === id);
  const [tab, setTab] = useState(0);
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const navigate = useNavigate();

  const subjects = useAtomValue(judgeSubjectsAtom(id));

  const parameters = decodeURIComponent(
    new URLSearchParams({
      subject: subjects.map((subject) => subject.id).join(","),
    }).toString()
  );

  const { data: judge, error } = useSuspenseQuery(judgeOptions(id, parameters));

  const data = useMemo(() => {
    if (!judge) return {};
    let result = {};
    if (
      tabs[tab] === "jurisprudence" &&
      shouldDisplayValue(judge.jurisprudence)
    )
      result = judge.jurisprudence;
    if (tabs[tab] === "tone" && shouldDisplayValue(judge.tone))
      result = judge.tone;
    if (tabs[tab] === "sentiment" && shouldDisplayValue(judge.sentiment))
      result = judge.sentiment;
    return result;
  }, [tab, judge]);

  const toggleCompare = useCallback(() => {
    if (isChecked !== -1) {
      const updated = removeItemAtIndex(comparing, isChecked);
      setComparing(updated);
      hotjar.event("compare-judges-removed");
    } else {
      setComparing((items) => [...items, id]);
      hotjar.event("compare-judges-added");
    }
  }, [comparing, id, isChecked, setComparing]);

  const goToItem = useCallback(() => {
    navigate(`/doctrina/judge/${id}`);
  }, [id, navigate]);

  if (error || !judge || Object.keys(judge).length === 0) {
    if (isChecked !== -1) {
      const updated = removeItemAtIndex(comparing, isChecked);
      setComparing(updated);
    }
    return null;
  }

  const {
    aba_ratings,
    court,
    display_name,
    date_of_birth,
    gender,
    political_affiliation,
    judge_opinions_count,
    jurisprudence,
    is_judge_x,
    race,
    sentiment,
    tone,
  } = judge;

  if (!display_name || !court || !jurisprudence || !sentiment || !tone) {
    if (isChecked !== -1) {
      const updated = removeItemAtIndex(comparing, isChecked);
      setComparing(updated);
    }
    return null;
  }

  const aba_ratings_formatted = aba_ratings?.rating
    ? `${aba_ratings.rating} (${aba_ratings.year})`
    : "n/a";

  const handleSetTab = (event, value) => setTab(value);
  const handleSetTabAlt = (event) => setTab(event.target.value);

  return (
    <Paper
      ref={ref}
      elevation={5}
      sx={{
        padding: 3,
        borderRadius: 3,
        borderRight:
          isChecked !== -1 && `25px solid ${theme.palette.secondary.main}`,
      }}
    >
      <Stack
        alignItems="center"
        flexWrap={[
          "wrap",
          "wrap",
          "wrap",
          params["width-between-500-and-820"] ? "wrap" : "nowrap",
        ]}
        direction={["column", "row"]}
        gap={[2, 3]}
      >
        <Stack
          spacing={1}
          alignSelf="stretch"
          justifyContent="space-between"
          maxWidth={[
            "auto",
            "100%",
            "100%",
            params["width-between-500-and-820"] ? "100%" : 225,
          ]}
          width={[
            "100%",
            "100%",
            "100%",
            params["width-between-500-and-820"] ? "100%" : "auto",
          ]}
        >
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography variant="h3" as="h2">
                {display_name}
                {!is_judge_x && judge_opinions_count < OPINION_COUNT_LIMIT ? (
                  <Tooltip
                    sx={{ marginLeft: -1, marginTop: -1 }}
                    title="Limited Sample"
                    placement="right"
                  >
                    <IconButton>
                      <InfoIcon
                        color="primary"
                        sx={{ width: 15, height: 15 }}
                      />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </Typography>
              <Typography color="text.light" variant="h5" as="h3">
                {court?.name}
              </Typography>
            </Stack>
            {!is_judge_x && (
              <Stack spacing={1}>
                {shouldDisplayValue(date_of_birth.year) && (
                  <StatCardOne label="Born" value={date_of_birth.year} />
                )}
                {shouldDisplayValue(race) && (
                  <StatCardOne label="Race" value={race} />
                )}
                {shouldDisplayValue(gender) && (
                  <StatCardOne label="Gender" value={gender} />
                )}
                {court.type !== "Federal Court of Appeals" && (
                  <>
                    {shouldDisplayValue(aba_ratings_formatted) && (
                      <StatCardOne
                        label="ABA Ratings"
                        value={aba_ratings_formatted}
                      />
                    )}
                    {shouldDisplayValue(political_affiliation) && (
                      <StatCardOne
                        label="Political Appointer"
                        value={political_affiliation}
                      />
                    )}
                  </>
                )}
              </Stack>
            )}
            {is_judge_x === 1 && (
              <AggregateTag
                sx={{ border: `2px solid ${theme.palette.primary.main}` }}
                tooltipText="This judge represents the average data across all available courts on the platform"
              />
            )}
          </Stack>
          {!isDetailPage && (
            <Stack direction="row" ref={buttonsRef}>
              <Tooltip title={isChecked !== -1 ? "Comparing" : "Compare"}>
                <IconButton onClick={toggleCompare}>
                  <CompareArrowsIcon
                    color={isChecked !== -1 ? "secondary" : "inherit"}
                    data-cy="compare-button-judges-card"
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title="Detailed View">
                <IconButton onClick={goToItem}>
                  <PreviewIcon sx={{ color: "#fec736" }} />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </Stack>
        {lgUp && !params["width-between-500-and-820"] && (
          <Divider orientation="vertical" flexItem />
        )}
        <Stack flex={1}>
          <Tabs
            value={tab}
            onChange={handleSetTab}
            aria-label="basic tabs example"
            sx={{ display: ["none", "none", "block"], mb: 2 }}
          >
            <Tab label="Jurisprudence" {...a11yProps(1)} />
            <Tab label="Sentiment" {...a11yProps(2)} />
            <Tab label="Tone" {...a11yProps(3)} />
          </Tabs>
          <Select
            sx={{
              display: ["block", "block", "none", "none"],
              width: "100%",
              mb: 3,
            }}
            fullWidth
            onChange={handleSetTabAlt}
            value={tab}
          >
            <MenuItem value={0}>Jurisprudence</MenuItem>
            <MenuItem value={1}>Sentiment</MenuItem>
            <MenuItem value={2}>Tone</MenuItem>
          </Select>
          <Stack flexDirection={["column", "column", "row"]} gap={[1, 1, 6]}>
            <Grid flex={1} spacing={2} container>
              {Object.keys(data).map((key, index) => (
                <Grid
                  key={index}
                  xs={6}
                  sm={params["width-between-500-and-820"] ? 12 : 4}
                  md={params["width-between-500-and-820"] ? 6 : 4}
                >
                  <StatPieGraphText
                    color={theme.palette.secondary.main}
                    comparing={false}
                    label={key}
                    value={data[key]}
                    isCompare={true}
                    isPrimary={true}
                  />
                </Grid>
              ))}
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Stack flex={1} justifyContent="center" alignItems="center">
              <StatRadarGraph
                data={normalizeData(data)}
                keys={["judge"]}
                indexBy="jurisprudence"
                colors={[theme.palette.primary.main]}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const JudgesCard = forwardRef(
  ({ id, buttonsRef, isDetailPage }, ref) => {
    return (
      <Suspense
        fallback={
          <Stack sx={{ alignItems: "center" }}>
            <CircularProgress />
          </Stack>
        }
      >
        <Fetch
          id={id}
          ref={ref}
          buttonsRef={buttonsRef}
          isDetailPage={isDetailPage}
        />
      </Suspense>
    );
  }
);

JudgesCard.displayName = "JudgesCard";
