import {
  Box,
  Button,
  Collapse,
  Stack,
  Tooltip,
  Tab,
  Tabs,
} from "@mui/material";
import { Suspense } from "react";
import { NavLink as RouterLink, useSearchParams } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import GavelIcon from "@mui/icons-material/Gavel";
import { DoctrinaTextSearch, SearchFilters } from "../../components";
import { hotjar } from "react-hotjar";
import { diff as deepDiff } from "deep-object-diff";
import { defaultValues } from "../../components/SearchDoctrina/SearchDoctrina";
import { useTheme } from "@mui/material/styles";

export const DoctrinaHeader = ({
  searchAreaRef,
  tabValue,
  onTabChange,
  filterDrawerOpen,
  setFilterDrawerOpen,
  searchParamsString,
  courtsItems,
  judgesItems,
  isComparingDoctrina,
  isComparingJudges,
  comparingDoctrina,
  comparingJudges,
  setComparingDoctrina,
  setComparingJudges,
  smUp,
  tabsRef,
  filterRef,
}) => {
  const [searchParams] = useSearchParams();
  const values = {};
  searchParams.forEach((value, key) => {
    if (key !== "query") {
      values[key] = value;
    }
  });
  const diff = deepDiff(defaultValues, values);
  const hasFilters =
    Object.keys(diff)
      .filter((key) => diff[key] !== "")
      .filter((key) => diff[key] !== "false")
      .filter((key) => diff[key] !== undefined).length > 0;
  const theme = useTheme();

  return (
    <Box
      ref={searchAreaRef}
      paddingY={[0.5, 2]}
      paddingX={[2, 4]}
      position="sticky"
      top={0}
      backgroundColor="white"
      zIndex={9}
    >
      <Stack spacing={1}>
        <Stack
          direction="row"
          spacing={[1, 2]}
          alignItems="center"
          sx={{
            width: "100%",
            minHeight: 48,
          }}
        >
          <Box flex={1}>
            <Suspense>
              <DoctrinaTextSearch />
            </Suspense>
          </Box>
          <Stack
            direction="row"
            spacing={[0.5, 1]}
            alignItems="center"
            sx={{
              transition: "all 200ms ease-in-out",
              "& .MuiButton-root": {
                [theme.breakpoints.down("sm")]: {
                  minWidth: "auto",
                  padding: "6px 8px",
                },
              },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip title={`Filter Doctrina`} placement="bottom">
                <Button
                  onClick={() => setFilterDrawerOpen(!filterDrawerOpen)}
                  color="primary"
                  variant="outlined"
                  startIcon={<FilterAltIcon />}
                  sx={{
                    "&:hover": {
                      backgroundColor: "grey.50",
                    },
                    [theme.breakpoints.down("sm")]: {
                      "& .MuiButton-startIcon": {
                        margin: 0,
                      },
                      "& .MuiButton-startIcon>*:nth-of-type(1)": {
                        fontSize: "20px",
                      },
                      "& .MuiButton-endIcon": {
                        display: "none",
                      },
                    },
                  }}
                >
                  {smUp && (
                    <>
                      Filter{" "}
                      {hasFilters
                        ? `(${
                            Object.keys(diff)
                              .filter((key) => diff[key] !== "")
                              .filter((key) => diff[key] !== "false")
                              .filter((key) => diff[key] !== undefined).length
                          })`
                        : ""}
                    </>
                  )}
                </Button>
              </Tooltip>
            </Box>

            <Box
              sx={{
                display: "flex",
                minWidth: 0,
                maxWidth:
                  (tabValue === 0 && isComparingDoctrina) ||
                  (tabValue === 1 && isComparingJudges)
                    ? [200, 300]
                    : 0,
                overflow: "hidden",
                transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1)",
              }}
            >
              <Box
                sx={{
                  transform:
                    (tabValue === 0 && isComparingDoctrina) ||
                    (tabValue === 1 && isComparingJudges)
                      ? "translateX(0)"
                      : "translateX(-100%)",
                  transition: "transform 200ms cubic-bezier(0.4, 0, 0.2, 1)",
                  whiteSpace: "nowrap",
                }}
              >
                <Stack direction="row" spacing={[0.5, 1]}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      if (tabValue === 0) {
                        setComparingDoctrina([]);
                      } else {
                        setComparingJudges([]);
                      }
                      hotjar.event(
                        `clear-${tabValue === 0 ? "courts" : "judges"}-compare`
                      );
                    }}
                    sx={{
                      borderColor: "grey.300",
                      color: "text.secondary",
                      "&:hover": {
                        borderColor: "grey.400",
                        backgroundColor: "grey.50",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "auto",
                        padding: "4px 8px",
                      },
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    to={`/doctrina${tabValue === 0 ? "" : "/judges"}/compare`}
                    component={RouterLink}
                    variant="contained"
                    fullWidth={!smUp}
                    data-cy={`main-compare-button-${
                      tabValue === 0 ? "doctrina" : "judges"
                    }`}
                    sx={{
                      ...(tabValue === 1 && {
                        backgroundColor: "secondary.main",
                        color: "text.primary",
                        "&:hover": {
                          backgroundColor: "secondary.dark",
                        },
                      }),
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "auto",
                        padding: "4px 8px",
                      },
                    }}
                  >
                    {smUp ? (
                      `Compare (${
                        tabValue === 0
                          ? comparingDoctrina.length
                          : comparingJudges.length
                      })`
                    ) : (
                      <>
                        Compare{" "}
                        <Box component="span" sx={{ ml: 0.5 }}>
                          (
                          {tabValue === 0
                            ? comparingDoctrina.length
                            : comparingJudges.length}
                          )
                        </Box>
                      </>
                    )}
                  </Button>
                </Stack>
              </Box>
            </Box>
          </Stack>
        </Stack>

        <Tabs
          ref={tabsRef}
          value={tabValue}
          onChange={onTabChange}
          variant="fullWidth"
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            minHeight: 48,
            "& .MuiTab-root": {
              minHeight: 48,
              py: 1.5,
            },
          }}
        >
          <Tab
            icon={<AccountBalanceIcon sx={{ fontSize: "1.25rem" }} />}
            iconPosition="start"
            label={`Courts${
              searchParamsString && courtsItems.length
                ? ` (${courtsItems.length > 4 ? "5+" : courtsItems.length})`
                : ""
            }`}
          />
          <Tab
            icon={<GavelIcon sx={{ fontSize: "1.25rem" }} />}
            iconPosition="start"
            label={`Judges${
              searchParamsString && judgesItems.length
                ? ` (${judgesItems.length > 4 ? "5+" : judgesItems.length})`
                : ""
            }`}
          />
        </Tabs>

        <Collapse ref={filterRef} in={filterDrawerOpen}>
          <Box sx={{ py: 1, px: [2, 4], backgroundColor: "grey.50" }}>
            <Suspense>
              <SearchFilters onClose={() => setFilterDrawerOpen(false)} />
            </Suspense>
          </Box>
        </Collapse>
      </Stack>
    </Box>
  );
};
