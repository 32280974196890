import { Box, Button, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { hotjar } from "react-hotjar";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { TextField } from "../TextField";
import { getFullCourtName } from "../../../services/store/courtAbbreviations";

export const DoctrinaTextSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultValues = {
    query: searchParams.get("query") ?? "",
  };

  const { handleSubmit, control, reset, watch, setValue } = useForm({
    defaultValues,
  });

  const onSubmit = (data) => {
    const expandedQuery = getFullCourtName(data.query);
    setSearchParams({ query: expandedQuery });
    hotjar.event("search-both-courts-judges-submit");
  };

  const handleReset = () => {
    setSearchParams([]);
    reset();
    hotjar.event("search-both-courts-judges-reset");
  };

  const query = watch("query");

  useEffect(() => {
    setValue("query", searchParams.get("query") ?? "");
  }, [searchParams, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack alignItems="center" gap={2} direction="row">
        <Box sx={{ flex: 1, position: "relative" }}>
          <TextField
            control={control}
            placeholder="Search Courts and Judges (e.g. SCOTUS, CA2, Roberts, Supreme Court)"
            label="Search Doctrina"
            fullWidth
            name="query"
            trim={false}
          />

          {query.length > 2 && (
            <IconButton
              onClick={handleReset}
              type="button"
              size="small"
              sx={{
                position: "absolute",
                right: 10,
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            minWidth: 0,
            maxWidth: query.length > 2 ? 300 : 0,
            overflow: "hidden",
            transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          <Box
            sx={{
              transform:
                query.length > 2 ? "translateX(0)" : "translateX(-100%)",
              transition: "transform 200ms cubic-bezier(0.4, 0, 0.2, 1)",
              whiteSpace: "nowrap",
            }}
          >
            <Button type="submit" variant="contained">
              Search
            </Button>
          </Box>
        </Box>
      </Stack>
    </form>
  );
};
