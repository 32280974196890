import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAtomValue, useSetAtom } from "jotai";
import { doctrinaFilterState, doctrinaTabState } from "../store";

export const useGoBackWithFilters = (
  options = { setTab: false, tabValue: 0 }
) => {
  const navigate = useNavigate();
  const filterState = useAtomValue(doctrinaFilterState);
  const setDoctrinaTab = useSetAtom(doctrinaTabState);

  return useCallback(
    (e) => {
      e?.preventDefault();

      if (options.setTab) {
        setDoctrinaTab(options.tabValue);
      }

      const params = new URLSearchParams();

      if (filterState.jurisdictions.length > 0) {
        params.append("jurisdictions", filterState.jurisdictions.join(","));
      }
      if (filterState.state.length > 0) {
        params.append("state", filterState.state.join(","));
      }
      if (filterState.court) {
        params.append("court_id", filterState.court.id);
      }
      if (filterState.query) {
        params.append("query", filterState.query);
      }

      navigate({
        pathname: "/doctrina",
        search: params.toString(),
      });
    },
    [navigate, filterState, options.setTab, options.tabValue, setDoctrinaTab]
  );
};
