export const COURT_ABBREVIATIONS = {
  // Federal Courts - Supreme Court
  SCOTUS: "Supreme Court of the United States",

  // Federal Courts - Circuit Courts
  CA1: "US Court of Appeals for the First Circuit",
  CA2: "US Court of Appeals for the Second Circuit",
  CA3: "US Court of Appeals for the Third Circuit",
  CA4: "US Court of Appeals for the Fourth Circuit",
  CA5: "US Court of Appeals for the Fifth Circuit",
  CA6: "US Court of Appeals for the Sixth Circuit",
  CA7: "US Court of Appeals for the Seventh Circuit",
  CA8: "US Court of Appeals for the Eighth Circuit",
  CA9: "US Court of Appeals for the Ninth Circuit",
  CA10: "US Court of Appeals for the Tenth Circuit",
  CA11: "US Court of Appeals for the Eleventh Circuit",
  CADC: "US Court of Appeals for the District of Columbia Circuit",
  CAFED: "US Court of Appeals for the Federal Circuit",

  // Notable Federal District Courts
  SDNY: "Southern District of New York",
  EDNY: "Eastern District of New York",
  NDNY: "Northern District of New York",
  WDNY: "Western District of New York",
  CDCA: "Central District of California",
  NDCA: "Northern District of California",
  EDCA: "Eastern District of California",
  SDCA: "Southern District of California",
  SDTX: "Southern District of Texas",
  EDTX: "Eastern District of Texas",
  NDTX: "Northern District of Texas",
  WDTX: "Western District of Texas",

  // State Courts
  CASCT: "California Supreme Court",

  // Specialized Courts
  "Tax Ct": "US Tax Court",
};

export const getFullCourtName = (searchTerm) => {
  if (!searchTerm) return searchTerm;

  const upperSearchTerm = searchTerm.trim().toUpperCase();

  const directMatch = COURT_ABBREVIATIONS[searchTerm];
  if (directMatch) return directMatch;

  for (const [abbr, fullName] of Object.entries(COURT_ABBREVIATIONS)) {
    if (abbr.toUpperCase() === upperSearchTerm) {
      return fullName;
    }
  }

  return searchTerm;
};
