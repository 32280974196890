import { Box, CircularProgress, Stack, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Default } from "../../layouts";
import { JudgeContainer } from "./JudgeContainer";
import { JudgeFilter } from "./JudgeFilter";
import { Suspense, memo } from "react";
import { useParams } from "react-router-dom";
import { useGoBackWithFilters } from "../../../services/hooks";

const JudgeView = memo(() => {
  const { id } = useParams();
  const handleBack = useGoBackWithFilters({ setTab: true, tabValue: 1 });

  return (
    <Default>
      <Stack sx={{ p: [0, 4], gap: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "row"],
            alignItems: ["flex-start", "center"],
            gap: [2, 4],
            mb: 2,
            minHeight: 40,
            width: "100%",
          }}
        >
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
            variant="outlined"
            sx={{ minWidth: "fit-content" }}
          >
            Back to Judges
          </Button>
          <Box
            sx={{
              flex: 1,
              minWidth: 0,
              width: ["100%", "auto"],
            }}
          >
            <JudgeFilter id={id} />
          </Box>
        </Box>
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <CircularProgress size="3rem" />
            </Box>
          }
        >
          <JudgeContainer id={id} />
        </Suspense>
      </Stack>
    </Default>
  );
});

export const Judge = () => <JudgeView />;

export default Judge;
