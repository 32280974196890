import {
  Home as HomeIcon,
  Mic as MicIcon,
  Search as SearchIcon,
  Work as WorkIcon,
} from "@mui/icons-material";

import { Anchor } from "../../components";
import { Stack, Box } from "@mui/material";
import { useIsCiceroEnabled } from "../../../services/hooks";
import { forwardRef } from "react";

export const DefaultNavigation = forwardRef(({ ...props }, ref) => {
  const isCiceroEnabled = useIsCiceroEnabled();

  return (
    <Stack flex={1} spacing={1}>
      <Box ref={ref}>
        <Stack spacing={1}>
          <Anchor to="/" end>
            <HomeIcon />
            Home
          </Anchor>
          <Anchor to="/doctrina">
            <SearchIcon />
            Doctrina
          </Anchor>
          <Anchor to="/debrief">
            <WorkIcon />
            DeBrief
          </Anchor>
          {isCiceroEnabled && (
            <Anchor to="/cicero">
              <MicIcon />
              Cicero
            </Anchor>
          )}
        </Stack>
      </Box>
    </Stack>
  );
});

DefaultNavigation.displayName = "DefaultNavigation";
