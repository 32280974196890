import {
  Box,
  CircularProgress,
  Typography,
  Paper,
  Link,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, {
  Suspense,
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from "react";
import { CiceroEmpty } from "./CiceroEmpty";
import { CiceroItems } from "./CiceroItems";
import { Default } from "../../layouts";
import { Error } from "../../views/Error";
import { useLocation } from "react-router-dom";
import { useCompletedAssignments } from "../../../services/queries";
import { CiceroOnboardingTour } from "../../components";
import { ciceroOnboardingState } from "../../../services/store";
import { useAtom } from "jotai";

const CiceroView = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const location = useLocation();
  const {
    data: completedAssignments,
    isLoading,
    error,
    refetch,
  } = useCompletedAssignments();
  const [ciceroOnboarding, setCiceroOnboarding] = useAtom(
    ciceroOnboardingState
  );
  const mainContentRef = useRef(null);
  const [refs, setRefs] = useState({
    newRecordingButtonRef: null,
    firstCardRef: null,
  });

  const assignments = useMemo(
    () => (Array.isArray(completedAssignments) ? completedAssignments : []),
    [completedAssignments]
  );

  const highlightRef = useMemo(() => {
    if (!ciceroOnboarding.isActive) return null;

    const hasAssignments = assignments.length > 0;

    switch (ciceroOnboarding.currentStep) {
      case 1:
        return mainContentRef;
      case 2:
        return refs.newRecordingButtonRef;
      case 3:
        return hasAssignments ? refs.firstCardRef : refs.emptyRecordingAreaRef;
      default:
        return null;
    }
  }, [
    ciceroOnboarding.isActive,
    ciceroOnboarding.currentStep,
    refs,
    assignments,
  ]);

  const handleCloseOnboarding = () => {
    setCiceroOnboarding({
      isActive: false,
      currentStep: 1,
    });
  };

  const handleNextStep = () => {
    if (ciceroOnboarding.currentStep === 3) {
      handleCloseOnboarding();
    } else {
      setCiceroOnboarding((prev) => ({
        ...prev,
        currentStep: prev.currentStep + 1,
      }));
    }
  };

  const handleBackStep = () => {
    setCiceroOnboarding((prev) => ({
      ...prev,
      currentStep: prev.currentStep - 1,
    }));
  };

  const handleRefsReady = useCallback((newRefs) => {
    setRefs(newRefs);
  }, []);

  useEffect(() => {
    if (location.pathname === "/cicero") {
      refetch();
    }
  }, [location.pathname, refetch]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size="3rem" />
      </Box>
    );
  }

  if (error) {
    return <Error message={error.message} />;
  }

  if (completedAssignments === "user not authorized to use Cicero") {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="70vh"
      >
        <Paper elevation={3} sx={{ padding: 4, maxWidth: 600 }}>
          <Typography variant="h3" marginBottom={1}>
            Access Denied
          </Typography>
          <Typography variant="body1">
            You do not have permission to access Cicero. To request an upgrade
            for your Rhetoric profile to include Cicero, or if you believe this
            message was sent in error, please contact us at{" "}
            <Link href="mailto:support@userhetoric.com">
              support@userhetoric.com
            </Link>{" "}
            for assistance.
          </Typography>
        </Paper>
      </Box>
    );
  }

  return (
    <Default>
      {!md && (
        <Box
          sx={{
            height: 80,
            borderBottom: 1,
            borderColor: "grey.100",
            bgcolor: "white",
          }}
        />
      )}
      <Box ref={mainContentRef}>
        {ciceroOnboarding.isActive && (
          <CiceroOnboardingTour
            onClose={handleCloseOnboarding}
            currentStep={ciceroOnboarding.currentStep}
            totalSteps={3}
            onNext={handleNextStep}
            onBack={handleBackStep}
            highlightRef={highlightRef}
          />
        )}
        <Box>
          {assignments.length > 0 ? (
            <CiceroItems
              assignments={assignments}
              onRefsReady={handleRefsReady}
            />
          ) : (
            <CiceroEmpty onRefsReady={handleRefsReady} />
          )}
        </Box>
      </Box>
    </Default>
  );
};

const LoadingFallback = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh"
  >
    <CircularProgress size="3rem" />
  </Box>
);

export const Cicero = () => (
  <Error>
    <Suspense fallback={<LoadingFallback />}>
      <CiceroView />
    </Suspense>
  </Error>
);

export default Cicero;
