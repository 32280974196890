import * as api from "../../../services/api";

import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Suspense, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Default } from "../../layouts";
import Grid from "@mui/material/Unstable_Grid2";
import { JudgesCompareCard } from "./JudgesCompareCard";
import { compareJudgesState } from "../../../services/store";
import { useAtomValue } from "jotai";
import { NavLink as RouterLink } from "react-router-dom";
import { useSuspenseQueries } from "@tanstack/react-query";
import { useGoBackWithFilters } from "../../../services/hooks";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const JudgesCompareView = () => {
  const comparing = useAtomValue(compareJudgesState);
  const [activeTab, setActiveTab] = useState(0);
  const goBack = useGoBackWithFilters({ setTab: true, tabValue: 1 });

  useEffect(() => {
    if (comparing.length < 2) {
      goBack();
    }
  }, [comparing.length, goBack]);

  const judges = useSuspenseQueries({
    queries: comparing.map((id) => ({
      queryKey: ["judge", id],
      queryFn: async () => await api.get(`/judges/${id}`),
    })),
  });

  return (
    <Stack minHeight="100vh" spacing={4}>
      <Box paddingTop={4} paddingX={4}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Box position="relative" mb={2} mt={2}>
              <Button
                startIcon={<ArrowBackIcon />}
                onClick={goBack}
                variant="outlined"
                sx={{
                  position: "absolute",
                  left: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                Back to Judges
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box paddingTop={1} paddingX={4}>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
          aria-label="judges data tabs"
        >
          <Tab label="Jurisprudence" {...a11yProps(0)} />
          <Tab label="Sentiment" {...a11yProps(1)} />
          <Tab label="Tone" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <Box backgroundColor="grey.100" flexGrow={1} padding={4}>
        <Grid alignItems="stretch" minHeight="80vh" container spacing={2}>
          {judges?.map((judge, index) => (
            <Grid
              xs={12}
              md={index === 0 ? 3 : comparing.length === 1 ? 6 : 3}
              key={judge.data.id}
            >
              <JudgesCompareCard
                primaryJudge={judges[0].data}
                active={index === 0}
                judge={judge.data}
                key={judge.data.id}
                activeTab={activeTab}
              />
            </Grid>
          ))}
          <Grid xs={12} md={3} alignSelf="stretch">
            <Stack
              border="1px dashed grey"
              borderColor="grey.300"
              height="100%"
              padding={3}
              justifyContent="center"
            >
              <Button to="/doctrina" component={RouterLink}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>Add Judge</Typography>
                  <AddIcon
                    fontSize="sm"
                    sx={{ position: "relative", top: -1 }}
                  />
                </Stack>
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export const JudgesCompare = () => (
  <Default>
    <Suspense
      fallback={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress size="3rem" />
        </Box>
      }
    >
      <JudgesCompareView />
    </Suspense>
  </Default>
);

export default JudgesCompare;
