import { Alert, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { TextField } from "../../components";
import { authTemp } from "../../../services/store";
import { useAtomValue } from "jotai";
import { useAuth } from "../../../services/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const defaultValues = {
  code: "",
  password: "",
  password_confirm: "",
};

const PASSWORD_REQUIREMENTS = [
  { rule: /.{8,}/, message: "At least 8 characters long" },
  { rule: /[A-Z]/, message: "Contains uppercase letter" },
  { rule: /[a-z]/, message: "Contains lowercase letter" },
  { rule: /[0-9]/, message: "Contains number" },
  { rule: /[^A-Za-z0-9]/, message: "Contains special character" },
];

export const ResetPasswordForm = () => {
  const { email } = useAtomValue(authTemp);
  const [genericError, setGenericError] = useState("");
  const [attempts, setAttempts] = useState(0);
  const navigate = useNavigate();
  const { handleForgotPasswordReset } = useAuth();

  const {
    watch,
    control,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting, touchedFields },
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  const password = watch("password");
  const passwordStrength = PASSWORD_REQUIREMENTS.filter(({ rule }) =>
    rule.test(password)
  ).length;

  const getPasswordStrengthColor = () => {
    if (passwordStrength <= 2) return "error.main";
    if (passwordStrength <= 4) return "warning.main";
    return "success.main";
  };

  const onSubmit = async (data) => {
    setGenericError("");
    try {
      await handleForgotPasswordReset(email, data.password, data.code);
      navigate("/login");
    } catch (error) {
      setAttempts((prev) => prev + 1);

      if (error.message.includes("Invalid verification code")) {
        setGenericError(
          "The verification code you entered is incorrect. Please check your email and try again."
        );
      } else if (error.message.includes("Password not strong enough")) {
        setGenericError(
          "Your password doesn't meet the security requirements. Please make it stronger."
        );
      } else {
        setGenericError(error.message);
      }
    }
  };

  useEffect(() => {
    setFocus("code");
  }, [setFocus]);

  if (!email) {
    navigate("/request-reset");
    return null;
  }

  const getFieldErrorSx = (fieldName) => ({
    "& .MuiFormHelperText-root": {
      color: errors?.[fieldName] ? "error.main" : "text.secondary",
      marginLeft: 0,
      marginTop: 1,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-error": {
        backgroundColor: "error.lighter",
      },
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={3}>
        {attempts >= 3 && (
          <Alert
            severity="warning"
            sx={{
              "& .MuiAlert-message": {
                color: "warning.dark",
              },
            }}
          >
            Having trouble? Make sure to check your spam folder for the
            verification code or request a new one.
          </Alert>
        )}

        <Stack gap={2}>
          <TextField
            label="Verification Code"
            name="code"
            control={control}
            rules={{
              required: "Verification code is required",
              minLength: {
                value: 6,
                message: "Code should be 6 characters",
              },
              maxLength: {
                value: 6,
                message: "Code should be 6 characters",
              },
              pattern: {
                value: /^[0-9]+$/,
                message: "Code should contain only numbers",
              },
            }}
            error={touchedFields.code && !!errors.code}
            helperText={
              errors?.code?.message ||
              "Enter the 6-digit code sent to your email"
            }
            inputProps={{ maxLength: 6 }}
            sx={getFieldErrorSx("code")}
          />

          <Stack spacing={1}>
            <TextField
              label="New Password"
              type="password"
              name="password"
              control={control}
              rules={{
                required: "A password is required",
                validate: (value) => {
                  if (passwordStrength < 3) {
                    return "Password is too weak. Please follow the requirements below.";
                  }
                  return true;
                },
              }}
              error={touchedFields.password && !!errors.password}
              helperText={errors?.password?.message}
              sx={getFieldErrorSx("password")}
            />

            {touchedFields.password && (
              <Stack spacing={0.5} sx={{ pl: 2 }}>
                <Typography
                  variant="caption"
                  sx={{ color: getPasswordStrengthColor(), fontWeight: 500 }}
                >
                  Password strength: {passwordStrength}/5
                </Typography>
                {PASSWORD_REQUIREMENTS.map(({ rule, message }, index) => (
                  <Typography
                    key={index}
                    variant="caption"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                      color: rule.test(password)
                        ? "success.main"
                        : "error.main",
                      fontWeight: rule.test(password) ? 400 : 500,
                    }}
                  >
                    {rule.test(password) ? "✓" : "×"} {message}
                  </Typography>
                ))}
              </Stack>
            )}
          </Stack>

          <TextField
            label="Confirm New Password"
            type="password"
            name="password_confirm"
            control={control}
            rules={{
              required: "Please confirm your password",
              validate: (value) =>
                watch("password") !== value
                  ? "Your passwords do not match"
                  : true,
            }}
            error={touchedFields.password_confirm && !!errors.password_confirm}
            helperText={errors?.password_confirm?.message}
            sx={getFieldErrorSx("password_confirm")}
          />
        </Stack>

        <Button
          variant="contained"
          disabled={isSubmitting}
          type="submit"
          size="large"
        >
          Reset Password
        </Button>

        {genericError && (
          <Alert
            severity="error"
            sx={{
              mt: 2,
              "& .MuiAlert-message": {
                color: "error.dark",
              },
            }}
          >
            {genericError}
          </Alert>
        )}
      </Stack>
    </form>
  );
};
