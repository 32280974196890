import { useInfiniteQuery } from "@tanstack/react-query";
import * as api from "../api";

export const useDoctrinaInfiniteData = (apiName, searchParamsString) => {
  return useInfiniteQuery({
    queryKey: [apiName, searchParamsString],
    queryFn: async ({ pageParam = 1 }) =>
      await api.get(
        `/v3/${apiName}?page=${pageParam}${
          searchParamsString ? `&${searchParamsString}` : ""
        }`
      ),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.num_pages === allPages.length
        ? undefined
        : allPages.length + 1;
    },
    throwOnError: true,
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
  });
}; 