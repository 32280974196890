import { Box, Button, CircularProgress, Grid, Stack } from "@mui/material";
import { DoctrinaCard, JudgesCard, SearchEmpty } from "../../components";
import { useMemo } from "react";

const TabContent = ({ isActive, children }) => (
  <Box sx={{ display: isActive ? "block" : "none" }}>{children}</Box>
);

export const DoctrinaContent = ({
  tabValue,
  courtsItems,
  judgesItems,
  isLoadingCourts,
  isLoadingJudges,
  isFetching,
  isLoading,
  isFetchingNextPageCourts,
  isFetchingNextPageJudges,
  hasNextPageCourts,
  hasNextPageJudges,
  handleFetchMore,
  firstCardRef,
  buttonsRef,
}) => {
  const courtsContent = useMemo(
    () => (
      <>
        {!isLoadingCourts && courtsItems.length === 0 && (
          <Grid container spacing={4}>
            <Grid item xs={12} sx={{ paddingBottom: 4 }}>
              <SearchEmpty text="No Courts found that fit your search." />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={4}>
          {courtsItems.map((id, index) => (
            <Grid item key={id} xs={12}>
              <DoctrinaCard
                ref={index === 0 ? firstCardRef : null}
                buttonsRef={index === 0 ? buttonsRef : null}
                id={id}
              />
            </Grid>
          ))}
        </Grid>
      </>
    ),
    [courtsItems, isLoadingCourts, firstCardRef, buttonsRef]
  );

  const judgesContent = useMemo(
    () => (
      <>
        {!isLoadingJudges && judgesItems.length === 0 && (
          <Grid container spacing={4}>
            <Grid item xs={12} sx={{ paddingBottom: 4 }}>
              <SearchEmpty text="No Judges found that fit your search." />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={4}>
          {judgesItems.map((id, index) => (
            <Grid item key={id} xs={12}>
              <JudgesCard
                ref={index === 0 ? firstCardRef : null}
                buttonsRef={index === 0 ? buttonsRef : null}
                id={id}
              />
            </Grid>
          ))}
        </Grid>
      </>
    ),
    [judgesItems, isLoadingJudges, firstCardRef, buttonsRef]
  );

  return (
    <Box
      sx={{
        backgroundColor: ["white", "grey.100"],
        padding: [0, 4],
        position: "relative",
      }}
    >
      {isFetching && !isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 16,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
          }}
        >
          <CircularProgress size={32} />
        </Box>
      )}
      <Box sx={{ opacity: isFetching && !isLoading ? 0.5 : 1 }}>
        <TabContent isActive={tabValue === 0}>
          <Box>
            {courtsContent}
            {(isFetchingNextPageCourts || hasNextPageCourts) &&
              courtsItems.length > 0 && (
                <Stack sx={{ py: 4, px: [2, 4] }}>
                  <Button
                    onClick={handleFetchMore}
                    variant="contained"
                    fullWidth
                  >
                    {isFetchingNextPageCourts ? (
                      <CircularProgress color="inherit" size="3rem" />
                    ) : (
                      "Load More"
                    )}
                  </Button>
                </Stack>
              )}
          </Box>
        </TabContent>

        <TabContent isActive={tabValue === 1}>
          <Box>
            {judgesContent}
            {(isFetchingNextPageJudges || hasNextPageJudges) &&
              judgesItems.length > 0 && (
                <Stack sx={{ py: 4, px: [2, 4] }}>
                  <Button
                    onClick={handleFetchMore}
                    variant="contained"
                    fullWidth
                  >
                    {isFetchingNextPageJudges ? (
                      <CircularProgress color="inherit" size="3rem" />
                    ) : (
                      "Load More"
                    )}
                  </Button>
                </Stack>
              )}
          </Box>
        </TabContent>
      </Box>
    </Box>
  );
};
